<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import AssignSupport from "@/components/meter/AssignSupport.vue";

export default {
  name: "Meters",
  components: {AssignSupport, TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Meters",
        path: "/meters",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,

      meterId: "",
      addSupportModal: false,
      meter: {},
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/meters-lists';
      let params = {
        has_customer: true,
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    dismissAddSupportModal() {
      this.addSupportModal = false;
    },
    addSupport(data) {
      this.meter = data;
      this.addSupportModal = true;
    },
    meterStatusUpdate(status = 'Disconnected', id) {
      this.$store.dispatch("Settings/loading", true);
      this.$store
          .dispatch("Meter/updateMeterStatus", {
            meterId: id,
            status: status,
          })
          .then(() => {
                this.$store.dispatch("Settings/loading", false);
                this.$toastr.s(
                    "Meter Error status successfully updated.",
                    "Success!"
                );
                // this.$set(this.meter, 'status', status);
              }, (err) => {
                // console.log(err.response);
                this.$store.dispatch("Settings/loading", false);
                this.$toastr.e(err.response.data.message, "Failed!");
              }
          );
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Meters</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/meters-lists"
              download-file-name="meters"
          />

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header w="100px" active="customer_id" :ordering="sort" @sortBy="sortBy('customer_id')"
                          title="Account No."/>
                  <Header active="customer_account_id" :ordering="sort" @sortBy="sortBy('customer_account_id')"
                          title="Consumer"/>
                  <Header w="100px" active="mobile_number" :ordering="sort" @sortBy="sortBy('mobile_number')"
                          title="Mobile No"/>
                  <Header w="100px" active="meter_number" :ordering="sort" @sortBy="sortBy('meter_number')"
                          title="Meter No"/>
                  <Header w="110px" active="connection_size" :ordering="sort" @sortBy="sortBy('connection_size')"
                          title="Size of Conn."/>
                  <Header w="100px" active="meter_status" :ordering="sort" @sortBy="sortBy('meter_status')"
                          title="Status"/>
                  <Header w="100px" active="meter_type" :ordering="sort" @sortBy="sortBy('meter_type')"
                          title="Type"/>
                  <Header active="installing_date" :ordering="sort" @sortBy="sortBy('installing_date')"
                          title="Install date"/>
                  <Header w="170px" active="current_consumption_unit" :ordering="sort"
                          @sortBy="sortBy('current_consumption_unit')" title="Last Reading"/>
                  <Header w="155px" active="reading_date_time" :ordering="sort" @sortBy="sortBy('reading_date_time')"
                          title="Last Reading At"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Meter_all',
                            'Meter_show',
                            'Meter_consumption',
                            'MeterError_log'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    <router-link :to="{name:'ViewConsumer', params:{id:data.customer_account.id}}">
                      {{ data.customer_account.account_number }}
                    </router-link>
                  </td>
                  <td>
                    <template v-if="data.customer_account">
                      <div>{{ data.customer_account.first_name }}</div>
                    </template>
                  </td>
                  <td>
                    {{ data.customer_account.mobile_number }}
                  </td>
                  <td>
                    <router-link :to="'/meters/' + data.id">{{ data.meter_number }}</router-link>
                  </td>
                  <td>
                    {{ data.connection_size }}
                  </td>
                  <td>
                    {{ data.meter_status }}
                  </td>
                  <td>
                    {{ data.meter_type }}
                  </td>
                  <td>
                    {{ data.installing_date | dateFormat }}
                  </td>
                  <td>{{ data.current_consumption_unit }}</td>
                  <td>{{ data.reading_date_time && data.reading_date_time | dateTimeFormat }}</td>
                  <td
                      v-if="checkUsrPermissions([
                      'Meter_all',
                      'Meter_show',
                      'Meter_consumption',
                      'MeterError_log'])"
                  >
                    <ActionsButton>
                      <a
                          v-if="checkUsrPermissions(['Meter_all', 'Meter_show'])"
                          href="#"
                          @click.prevent="$router.push('/meters/' + data.id)"
                      >
                        View
                      </a>
                      <a
                          v-if="checkUsrPermissions(['Meter_all', 'Meter_consumption'])"
                          href="#"
                          @click.prevent="$router.push(`/meters/${data.id}/consumptions`)"
                      >
                        Consumptions
                      </a>
                      <router-link
                          v-if="checkUsrPermissions(['Meter_all', 'MeterError_log'])"
                          :to="'/meters/' + data.id + '/errors'"
                      >
                        Error Logs
                      </router-link>
                      <a
                          v-if="checkUsrPermissions(['CAccAssign_team', 'CAcc_all'])"
                          href="#"
                          @click.prevent="addSupport(data)"
                      >
                        Assign Support
                      </a>
                      <a
                          v-if="checkUsrPermissions(['Meter_all', 'MeterStatus_update'])"
                          href="#"
                          @click.prevent="meterStatusUpdate(data.status == 'DISCONNECTED' ? 'CONNECTED' : 'DISCONNECTED', data.id)"
                      >
                        {{ data.status == 'DISCONNECTED' ? 'CONNECT' : 'DISCONNECT' }}
                      </a>
                      <router-link
                          v-if="checkUsrPermissions(['History_all'])"
                          :to="'/meters/' + data.id + '/history'"
                      >
                        History
                      </router-link>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        v-if="addSupportModal"
        :closeOnBackdrop="false"
        title="Assign Support"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="addSupportModal"
    >
      <assign-support
          :meter="meter"
          @closeSupportModal="dismissAddSupportModal"
      ></assign-support>
    </CModal>
  </div>
</template>
